<template>
    <ModalForm
        id="modalFormEditUnitType"
        :title="titleModal"
    >
        <Form as="el-form" :validation-schema="validationSchema" @submit="save">
            <EditFormMaster>
                <div class="fv-row rowItemControl">
                    <label class="required fs-6 fw-bold mb-2">Nombre</label>
                    <Field  name="name" v-slot="{value, field, errorMessage}">
                        <el-form-item :error="errorMessage">
                            <input
                                v-bind="field"
                                class="form-control"
                                type="text" 
                                placeholder="Nombre"
                                :validate-event="false"
                                :disabled="onlyRead"
                                :model-value="value"
                            />
                        </el-form-item>
                    </Field> 
                </div>  
            </EditFormMaster>
        </Form>
    </ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, onUpdated, toRefs, computed } from 'vue';
import { Field, Form, useField } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../components/c-modal-form/ModalForm.vue";
import EditFormMaster from "../../components/c-module-master/edit-form-master.vue"

export default defineComponent({
    components:{
        Form,
        Field,
        ModalForm,
        EditFormMaster
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Tipo de Unidad"
        },
        article: {
            type: Object,
            required: false,
            default: () => null
        }
    },
    setup(props) {
        const { article } = toRefs(props)
        let txtName = ref("");
        let isOnlyRead = false;
        const validationSchema = Validations.object().shape({
            name: Validations.string().required().label("name")
        });

        const save = (form) => {
            if(form)
            console.log(123);
        }

        onBeforeMount(async() => {
            isOnlyRead = props.mode == "V";
        });
        
        watch(article, (nValue, oValue) => {
            debugger
            if (nValue) {
                validationSchema.fields.name = props.article.name;
            }
        });

        return {
            isOnlyRead,
            txtName,
            validationSchema
        }
    },
})
</script>

<style lang="scss">

    .el-select {
        width: 100%;
    }

    .itemControl {
        min-width: 250px;
    }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }


</style>
