<template>
    <slot></slot>
    <!-- <div class="rowDivControl">
        <div class="fv-row mb-7 itemDivControl">
            <label class="fs-6 fw-bold mb-2">Fecha Creación</label>
            <input type="text" class="form-control form-control-solid itemControl" placeholder="Fecha de alta" :value="creationDate" disabled/>
        </div>
        <div class="fv-row mb-7 itemDivControl">
            <label class="fs-6 fw-bold mb-2">Fecha Edición</label>
            <input type="text" class="form-control form-control-solid itemControl" placeholder="Fecha de edición" :value="editionDate" disabled/>
        </div>
    </div>
    <div style="display: flex;">
        <input 
            class="form-check-input" 
            type="checkbox"
            disabled
            :checked="isActive"
        />
        <label class="fs-6 fw-bold mb-2" style="padding-left: 5px;">Activo</label>
    </div> -->
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        creationDate: {
            type: Date,
            required: false,
            default: () => new Date()
        },
        editionDate: {
            type: Date,
            required: false,
            default: () => new Date()
        },
        isActive: {
            type: Boolean,
            required: false,
            default: () => true
        }
    }
   
})
</script>

<style lang="scss">
    .itemControl {
        min-width: 250px;
    }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }


</style>
