<template>
    <div 
        class="modal fade"
        :id="id"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" :id="`${id}_header`">
                    <h2 class="fw-bolder">{{title}}</h2>
                    <div
                        :id="`${id}_close`"
                        data-bs-dismiss="modal"
                        class="btn btn-icon btn-sm btn-active-icon-primary"
                    >
                        <span class="svg-icon svg-icon-1">
                        <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div
                        class="scroll-y me-n7 pe-7"
                        :id="`${id}_scroll`"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#type_fishing_form_header"
                        data-kt-scroll-wrappers="#type_fishing_form_scroll"
                        data-kt-scroll-offset="300px"
                    >
                        <slot></slot>
                    </div>
                </div>
                <div class="modal-footer flex-right">
                    <button
                        type="reset"
                        class="btn btn-light me-3"
                        :id="`${id}_close`"
                        data-bs-dismiss="modal"
                    >
                        Cancelar
                    </button>
                    <button
                        :data-kt-indicator="loading ? 'on' : null"
                        class="btn btn-lg btn-primary"
                        @click="save"
                    >
                        <span v-if="!loading" class="indicator-label">
                            Guardar
                            <span class="svg-icon svg-icon-3 ms-2 me-0">
                                <inline-svg src="icons/duotune/arrows/arr064.svg" />
                            </span>
                        </span>
                        <span v-if="loading" class="indicator-progress">
                            Guardando...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "@vue/runtime-core";
// import { hideModal } from "@/core/helpers/dom";
// import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
    name: "app-modal-form",
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        id: {
            type: String,
            required: true
        },
        fnCallbackSubmit: {
            type: Function,
            required: false
        },
        initialValues: {
            type: Object,
            required: false
        }
    },
    setup(props) {
        const loading = ref(false);

        const save = () => {
            if(props.fnCallbackSubmit) {
                props.fnCallbackSubmit();
            }
        }

        return {
            loading,
            save
        }
    }
});
</script>