<template>
<ModuleMaster 
    :requireSearchField="true"
    :requireAddButton="true"
    :requirePaginator="true"
    :labels="labels"
    :numItemsPaginator="100"
    :itemsPerPage="5"
    :fnCallbackChangePage="handleChangePage"
    :fnCallbackCrudAction="handleCrudAction"
>
    <Datatable :headers="headers" :requireActions="true" :requireColStatus="true">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireColStatus="true"
        >
            <CrudActions :itemID="row.ID" :callbackCrudAction="handleCrudAction"/>
        </Datarow>
    </Datatable>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalFormEditUnitType" ref="btnForm"></button>
<UnitTypeEditForm :mode="modeForm" :title="titleEditFormAction"/>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import ModuleMaster from "../../components/c-module-master/module-master.vue";
import CrudActions from "../../components/c-module-master/crud-actions.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../common/formaterToDatatable";
import UnitTypeEditForm from "./unitType-edit-form.vue";

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        CrudActions,
        UnitTypeEditForm
    },
    setup() {
        //VARIABLES Y CONSTANTES
        let records = ref([]);
        let modeForm = ref("N");
        let titleEditForm = ref("Nueva Unidad de Medida");
        let currentPage = 1;
        const cantRecordsPage = 5;
        let totalItems = ref(0);
        const btnForm = ref(null);
        const currentItem = ref(null);

        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Tipos de Unidad",
            titleNewCrudAction: "Nuevo Tipo de Unidad",
        });

        const arrayTitleActions = [
            { action: "N", title: "Nuevo Tipo de Unidad" },
            { action: "M", title: "Modificar Tipo de Unidad" },
            { action: "V", title: "Visualizar Tipo de Unidad" }
        ];

        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "name", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true },
            { PropName: "creationDate", HeadLabel: "Fecha Creación", Type: "text", VisibleInGrid: true },
            { PropName: "editionDate", HeadLabel: "Fecha Edición", Type: "text", VisibleInGrid: true },
            { PropName: "status", HeadLabel: "Estaus", Type: "check", VisibleInGrid: true }            
        ]);

        //HOOKS
        onBeforeMount(async() => {
            await getUnitTypes();
        })

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable);
        });

        const currentArticle = computed(() => {
            return currentItem.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        })

        //FUNCIONES
        const handleChangePage = async(page) => {
            currentPage = page;
            await getUnitTypes();
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;
            titleEditForm.value = arrayTitleActions.find(ac => ac.action == action).title;
            if(action === "M" || action === "V") {
                await getArticleById("d52c85a9-c1b0-49c2-9ce0-9e4cc6896fa9");
            }
            if(btnForm.value) {
               btnForm.value.click();
            }
        }

        const getUnitTypes = async() => {
            records.value = reactive([
                { id: 1, name: "Pieza", creationDate: "23-junio-2022", editionDate: "23-junio-2022", status: true },
                { id: 2, name: "Kilogramo", creationDate: "23-junio-2022", editionDate: "23-junio-2022", status: true },
                { id: 3, name: "Metro", creationDate: "23-junio-2022", editionDate: "23-junio-2022", status: false },
                { id: 4, name: "Galón", creationDate: "23-junio-2022", editionDate: "23-junio-2022", status: true },
            ]);
        }

        const getArticleById = async(id) => {
            ApiService.get(`articles/${id}`).then(res => {
                currentItem.value = res.data;
            });
        }


        return {
            //variables y constantes
            labels,
            btnForm,

            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,

            //funciones
            handleChangePage,
            handleCrudAction
        }
        
    },
})
</script>
